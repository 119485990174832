

/* данные для калькулятора */
var data = [
    {
        name: 'Biotrue ONE day',
        params: {
            0: 70,
            1: 'Передовые',
            2: 'Однодневные',
            3: '30 шт.',
            4: 78,
            5: 42
        },
        url: '../images/biotrue-one-day-30.jpg'
    },
    {
        name: 'Dailies Aqua comfort plus',
        params: {
            0: 62,
            1: 'Проверенные',
            2: 'Однодневные',
            3: '30 шт.',
            4: 69,
            5: 26
        },
        url: '../images/dailies-aqua-comfort-plus-30.jpg'
    },
    {
        name: 'Dailies TOTAL 1',
        params: {
            0: 111,
            1: 'Передовые',
            2: 'Однодневные',
            3: '30 шт.',
            4: 33,
            5: 156
        },
        url: '../images/dailies-total-1-30.jpg'
    },
    {
        name: 'Soflens Daily Disposable',
        params: {
            0: 52,
            1: 'Проверенные',
            2: 'Однодневные',
            3: '30 шт.',
            4: 59,
            5: 24
        },
        url: '../images/soflens-daily-disposable-30.jpg'
    },
    {
        name: 'MyDay',
        params: {
            0: 128,
            1: 'Передовые',
            2: 'Однодневные',
            3: '30 шт.',
            4: 54,
            5: 100
        },
        url: '../images/myday-30.jpg'
    },
    {
        name: 'Proclear 1 day',
        params: {
            0: 87,
            1: 'Проверенные',
            2: 'Однодневные',
            3: '30 шт.',
            4: 60,
            5: 28
        },
        url: '../images/proclear-1-day-30.jpg'
    },
    {
        name: 'Сlariti 1 day',
        params: {
            0: 89,
            1: 'Передовые',
            2: 'Однодневные',
            3: '30 шт.',
            4: 56,
            5: 86
        },
        url: '../images/clariti-1-day-30.jpg'
    },
    {
        name: 'ACUVUE 1day Moist',
        params: {
            0: 77,
            1: 'Проверенные',
            2: 'Однодневные',
            3: '30 шт.',
            4: 58,
            5: 33
        },
        url: '../images/acuvue-1day-moist-30.jpg'
    },
    {
        name: 'ACUVUE 1day Trueye',
        params: {
            0: 90,
            1: 'Проверенные',
            2: 'Однодневные',
            3: '30 шт.',
            4: 46,
            5: 118
        },
        url: '../images/acuvue-1day-trueye-30.jpg'
    },
    {
        name: 'ACUVUE Oasys 1 day',
        params: {
            0: 98,
            1: 'Передовые',
            2: 'Однодневные',
            3: '30 шт.',
            4: 38,
            5: 121
        },
        url: '../images/acuvue-oasys-1-day-30.jpg'
    },
    {
        name: 'Dailies Aqua comfort plus',
        params: {
            0: 51,
            1: 'Проверенные',
            2: 'Однодневные',
            3: '90 шт.',
            4: 69,
            5: 26
        },
        url: '../images/dailies-aqua-comfort-plus-90.jpg'
    },
    {
        name: 'Dailies TOTAL 1',
        params: {
            0: 104,
            1: 'Передовые',
            2: 'Однодневные',
            3: '90 шт.',
            4: 33,
            5: 156
        },
        url: '../images/dailies-total-1-90.jpg'
    },
    {
        name: 'Biotrue ONE day',
        params: {
            0: 61,
            1: 'Передовые',
            2: 'Однодневные',
            3: '90 шт.',
            4: 78,
            5: 42
        },
        url: '../images/biotrue-one-day-90.jpg'
    },
    {
        name: 'Soflens Daily Disposable',
        params: {
            0: 43,
            1: 'Проверенные',
            2: 'Однодневные',
            3: '90 шт.',
            4: 59,
            5: 24
        },
        url: '../images/soflens-daily-disposable-90.jpg'
    },
    {
        name: 'MyDay',
        params: {
            0: 117,
            1: 'Передовые',
            2: 'Однодневные',
            3: '90 шт.',
            4: 54,
            5: 100
        },
        url: '../images/myday-90.jpg'
    },
    {
        name: 'ACUVUE 1day Moist',
        params: {
            0: 70,
            1: 'Проверенные',
            2: 'Однодневные',
            3: '90 шт.',
            4: 58,
            5: 33
        },
        url: '../images/acuvue-1day-moist-90.jpg'
    },
    {
        name: 'ACUVUE 1day Trueye',
        params: {
            0: 82,
            1: 'Проверенные',
            2: 'Однодневные',
            3: '90 шт.',
            4: 46,
            5: 118
        },
        url: '../images/acuvue-1day-trueye-90.jpg'
    },
    {
        name: 'ACUVUE Oasys 1 day',
        params: {
            0: 86,
            1: 'Передовые',
            2: 'Однодневные',
            3: '90 шт.',
            4: 38,
            5: 121
        },
        url: '../images/acuvue-oasys-1-day-90.jpg'
    },
    {
        name: 'AirOptix Aqua',
        params: {
            0: 18,
            1: 'Проверенные',
            2: 'Плановые',
            3: '',
            4: 33,
            5: 138
        },
        url: '../images/airoptix-aqua.jpg'
    },
    {
        name: 'AirOptix Hydraglyde',
        params: {
            0: 24,
            1: 'Передовые',
            2: 'Плановые',
            3: '',
            4: 33,
            5: 138
        },
        url: '../images/airoptix-hydraglyde.jpg'
    },
    {
        name: 'AirOptix Night & Day Aqua',
        params: {
            0: 32,
            1: 'Проверенные',
            2: 'Плановые',
            3: '',
            4: 24,
            5: 175
        },
        url: '../images/airoptix-night-&-day-aqua.jpg'
    },
    {
        name: 'ULTRA',
        params: {
            0: 31,
            1: 'Передовые',
            2: 'Плановые',
            3: '',
            4: 46,
            5: 163
        },
        url: '../images/ultra.jpg'
    },
    {
        name: 'Biofinity',
        params: {
            0: 32,
            1: 'Передовые',
            2: 'Плановые',
            3: '',
            4: 48,
            5: 160
        },
        url: '../images/biofinity.jpg'
    },
    {
        name: 'Acuvue Oasys',
        params: {
            0: 23,
            1: 'Проверенные',
            2: 'Плановые',
            3: '',
            4: 38,
            5: 147
        },
        url: '../images/acuvue-oasys.jpg'
    },
    {
        name: 'PureVision 2',
        params: {
            0: 13,
            1: 'Проверенные',
            2: 'Плановые',
            3: '',
            4: 36,
            5: 130
        },
        url: '../images/purevision-2.jpg'
    },
    {
        name: 'Soflens 59',
        params: {
            0: 9,
            1: 'Проверенные',
            2: 'Плановые',
            3: '',
            4: 59,
            5: 22
        },
        url: '../images/soflens-59.jpg'
    },
    {
        name: 'Biomedics 55 Evolution UV',
        params: {
            0: 11,
            1: 'Проверенные',
            2: 'Плановые',
            3: '',
            4: 55,
            5: 28.2
        },
        url: '../images/biomedics-55-evolution-uv.jpg'
    },
    {
        name: 'Proclear',
        params: {
            0: 13,
            1: 'Проверенные',
            2: 'Плановые',
            3: '',
            4: 62,
            5: 42
        },
        url: '../images/proclear.jpg'
    }
];
/* /данные для калькулятора */

/* скрипт калькултора */
//$(document).tooltip();

$(document).ready(function () {
    function createRange(n) {
        var m = n + 1;
        var arr = Array(m);
        for (var i = 0; i < m; i++) {
            arr[i] = i;
        };
        return arr;
    }

    var dont_update = false;
//    var day_cost = [];
    var results = [];
    var day_cost = createRange(150);

//    day_cost[0] = 0;
//
//    $.each(sort(data, 0, 0), function (index, value) {
//        day_cost[day_cost.length] = value.params[0];
//    });
//
//    day_cost[day_cost.length] = 150;

    var day_cost_index_min = 0;
    var day_cost_index_max = day_cost.length-1;

    $("#slider").slider({
        range: true,
        min: 0,
        max: day_cost.length-1,
        values: [0, day_cost.length - 1],
        slide: function (event, ui) {
            updateSliderLegend();
        },
        change: function (event, ui) {
            updateSliderLegend();
            $('#results').fadeOut(100, function () {
                $(this).html("");
                get_results();
            });
        }
    });

    updateSliderLegend();

    $(document).tooltip({
        content: function () {
            return $(this).prop('title');
        }
    });

    $(document).on('change', '.slider-input', function () {
        $("#slider").slider("option", "values", [
            $('.slider-input[data-attr="min"]').val(),
            $('.slider-input[data-attr="max"]').val()
        ]);
    });

    function updateSliderLegend() {
        $("#amount").html(
            "от "
            + getSliderInput(day_cost[$("#slider").slider("values", 0)], 'min')
            + " до "
            + getSliderInput(day_cost[$("#slider").slider("values", 1)], 'max')
            + " ₽"
        );
    }

    function getSliderInput(value, attr) {
        return '<input class="slider-input" data-attr="' + attr + '" type="number" value="' + value + '">'
    }

    function intersect(a, b) {
        var t;
        if (b.length > a.length) t = b, b = a, a = t; // indexOf to loop over shorter
        return a.
        filter(function (e) {
            return b.indexOf(e) > -1;
        }).filter(function (e, i, c) { // extra step to remove duplicates
            return c.indexOf(e) === i;
        });
    }

    get_results();

    $(document).on('change', 'input[name="radio-3"]', function(){

        if($(this).val() === 'Однодневные') {

            $('#packing')
                .fadeIn(100);

            $('#water')
                .fadeIn(100);

            $('#oxygen')
                .fadeIn(100);

            $('#water-2')
                .fadeOut(100, function(){

                    $('#water-1')
                        .fadeIn(100);
                });

            $('#oxygen-2')
                .fadeOut(100, function(){

                    $('#oxygen-1')
                        .removeClass('d-none')
                        .fadeIn(100);
                });

        } else
        {
            $('#packing')
                .fadeOut(100);

            $('#water')
                .fadeIn(100);

            $('#oxygen')
                .fadeIn(100);

            $('#water-1')
                .fadeOut(100, function(){

                    $('#water-2')
                        .fadeIn(100);
                });

            $('#oxygen-1').fadeOut(100, function(){

                $('#oxygen-2')
                    .fadeIn(100);
            });
        }
        get_results();
    });

    $(document).on('change', 'input[type="radio"]', function () {
        $('#results').fadeOut(100, function () {
            $(this).html("");
            clearSlider();
            get_results();
            dinamicSlider();
        });
    });

    function get_results() {

        // var day_cost = [];
        //
        // $.each(sort(data, 0, 0), function(index, value){
        //     day_cost[day_cost.length] = value.params[0];
        // });

        var form_params = [
            {
                index: 0,
                valueMin: day_cost[$('#slider').slider('values', 0)],
                valueMax: day_cost[$('#slider').slider('values', 1)]
            },
            {
                index: 1,
                value: $('input[name="radio-2"]:checked').val()
            },
            {
                index: 2,
                value: $('input[name="radio-3"]:checked').val()
            },
            {
                index: 3,
                value: $('input[name="radio-4"]:checked').val()
            },
            {
                index: 4,
                value: $('input[name="radio-5"]:checked').val()
            },
            {
                index: 5,
                value: $('input[name="radio-6"]:checked').val()
            },
        ];

        // console.log("form_params: ", form_params);

        var selected = [];

        selected[0] = [];

        $.each(data, function(data_index, data_value){

            if (
                data_value.params[0] >= form_params[0].valueMin &&
                data_value.params[0] <= form_params[0].valueMax
            )
                selected[0][selected[0].length] = data_value;
        });

        selected[1] = [];
        selected[2] = [];

        //if(form_params[1].value) {
        if(!form_params[1].value || form_params[1].value === undefined) {
            $.each(selected[0], function (data_index, data_value) {
                selected[1][selected[1].length] = data_value;
            });
        } else {

            $.each(selected[0], function (data_index, data_value) {
                if (data_value.params[1] === form_params[1].value) {
                    selected[1][selected[1].length] = data_value;
                }
            });
        }

        if(form_params[2].value) {
            $.each(selected[1], function (data_index, data_value) {
                if (data_value.params[2] === form_params[2].value) {
                    selected[2][selected[2].length] = data_value;
                }
            });
        }

        selected[3] = [];
        selected[4] = [];

        if(!form_params[3].value || form_params[3].value == 0) {
            selected[3] = selected[2];
        } else {
            $.each(selected[2], function (data_index, data_value) {
                if (data_value.params[3] === form_params[3].value) {
                    selected[3][selected[3].length] = data_value;
                }
            });
        }

        if(!form_params[4].value || form_params[4].value == 0) {
            selected[4] = selected[3];
        } else {
            $.each(selected[3], function (data_index, data_value) {
                switch (form_params[4].value) {
                    case "<40" :
                        if (data_value.params[4] < 40)
                            selected[4][selected[4].length] = data_value;
                        break;
                    case ">40" :
                        if (data_value.params[4] >= 40)
                            selected[4][selected[4].length] = data_value;
                        break;
                    case "<50" :
                        if (data_value.params[4] < 50)
                            selected[4][selected[4].length] = data_value;
                        break;
                    case "50-60" :
                        if (data_value.params[4] >= 50 && data_value.params[4] < 60)
                            selected[4][selected[4].length] = data_value;
                        break;
                    case "60-70" :
                        if (data_value.params[4] >= 60 && data_value.params[4] < 70)
                            selected[4][selected[4].length] = data_value;
                        break;
                    case ">70" :
                        if (data_value.params[4] >= 70)
                            selected[4][selected[4].length] = data_value;
                        break;
                }
            });
        }

        selected[5] = [];
        if(!form_params[5].value || form_params[5].value == 0) {
            selected[5] = selected[4];
        } else {
            $.each(selected[4], function (data_index, data_value) {

                switch (form_params[5].value) {
                    case "<35" :
                        if (data_value.params[5] < 35)
                            selected[5][selected[5].length] = data_value;
                        break;
                    case ">35" :
                        if (data_value.params[5] >= 35)
                            selected[5][selected[5].length] = data_value;
                        break;
                    case "<125" :
                        if (data_value.params[5] < 125)
                            selected[5][selected[5].length] = data_value;
                        break;
                    case ">125" :
                        if (data_value.params[5] >= 125)
                            selected[5][selected[5].length] = data_value;
                        break;
                }
            });
        }

        //console.log(selected);
        results.length = 0;
        results = [];

        if(form_params[5].value)
            results = selected[5];
        else if(form_params[4].value)
            results = selected[4];
        else if(form_params[3].value)
            results = selected[3];
        else if(form_params[2].value)
            results = selected[2];
        else if(form_params[1].value)
            results = selected[1];
        else
            results = selected[0];

        var sortBy = 0;
        switch($('input[name="sort"]:checked').val()) {
            case 'water':
                sortBy = 4; break;
            case 'oxygen':
                sortBy = 5; break;
        }
        printResults(sort(results, sortBy, 0));
    }

    function clearSlider() {
        $("#slider").slider('values', [0, 150]);
    }

    function dinamicSlider() {
        if (results.length > 0) {
            $.each(day_cost, function (index, value) {
                if (value === results[0].params[0]) {
                    day_cost_index_min = index;
                }
                if (value === results[results.length - 1].params[0]) {
                    day_cost_index_max = index;
                }
            });
            $("#slider").slider('values', [day_cost_index_min, day_cost_index_max]);
        }
        /*if(results.length > 0) {
            $.each(day_cost, function (index, value) {
                if(value === results[0].params[0])
                    day_cost_index_min = index;
                if(value === results[results.length-1].params[0])
                    day_cost_index_max = index;
            });

            dont_update = true;
            $("#slider").slider('values', day_cost_index_min, day_cost_index_max);
        }*/
    }

    function printResults(results) {

        var str = "<div class='col-12'><div class='block_calc__title'><h2>Результат подбора линз</h2></div></div>";
        $.each(results, function(index, data){

            str += "<div class='col-sm-6'><div class='result_div' style='padding: 20px; box-shadow: 0 0 5px rgba(0,0,0,0.2); margin: 0px 15px 30px 15px;display:flex;justify-content: flex-start;'><div style=''><img src=" + data.url + " alt='' width='150px'></div><div style='padding-left:20px;'><h5 style='font-weight:bold; font-size:14px;margin:20px 0px 10px 0px;'>" + data.name + "</h5>";
            // str += data.params[1] + ", " +
            //     data.params[2] + "<br>";
            if(data.params[3] !== '')
                str += data.params[3] + ", ";
            str += "<div style='font-weight:normal; font-size:13px;line-height:20px;'>Влагосодержание " + data.params[4] + "%<br> " +
                "Кислородная проницаемость Dk/t > " + data.params[5] + "<br>" +
                "Стоимость в день  - <b>" + data.params[0] + "</b> руб" +
                " <sup title=\"При правильном режиме ношения, рекомендуемом производителями\"><i class=\"fas fa-question-circle\"></i></sup>" +
                "</div></div></div></div>";

        });

        //console.log($('input[name="radio-2"]').val());

        if(!$('input[name="radio-3"]:checked').val())
            str = 'Выберите режим замены линз для начала подбора';
        else if(str === '')
            str = 'Нет результатов отвечающих заданным параметрам';

        $('#results')
            .fadeIn(100, function(){
                $(this).html(str);
            });
    }

    //printResults(sort(data, 4));

    function sort(sortData, sortIndex, sortOrder) {

        if(!sortOrder) sortOrder = 0;

        do {
            var perestanovka = false;
            $.each(sortData, function (index, value) {

                if (index > 0) {

                    if(sortOrder === 0) {
                        if (sortData[index - 1].params[sortIndex] > sortData[index].params[sortIndex]) {

                            var tmp = sortData[index];
                            sortData[index] = sortData[index - 1];
                            sortData[index - 1] = tmp;
                            perestanovka = true;
                        }
                    } else {
                        if (sortData[index - 1].params[sortIndex] < sortData[index].params[sortIndex]) {

                            var tmp = sortData[index];
                            sortData[index] = sortData[index - 1];
                            sortData[index - 1] = tmp;
                            perestanovka = true;
                        }
                    }
                }
            });
        }while(perestanovka);
        return sortData;
    }

    $(document).on('click', '#get_results', function() {

        get_results();
    });

});
/* /скрипт калькулятора */
